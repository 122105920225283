import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title={<span style={{
      color: "#000000"
    }}>Spotlight</span>} link="https://techcrunch.com/2020/11/23/snapchat-launches-a-tiktok-like-feed-called-spotlight-kick-started-by-paying-creators/" bg="linear-gradient(to right, #FFFC00 0%, #FFFC00 50%, #FFFFFF 100%)" mdxType="ProjectCard">
  <span style={{
        color: "#000000"
      }}>
    Snapchat's first ever dedicated video consumption experience, powered by
    community generated content
  </span>
    </ProjectCard>
    <ProjectCard title="Ads Reporting" link="https://www.facebook.com/business/help/487269218011981?id=768381033531365" bg="linear-gradient(to right, #1978F2 0%, #1978F2 50%, #3B5988 100%)" mdxType="ProjectCard">
  Facebook's reporting tool for in depth analysis and optimization of ad
  performance
    </ProjectCard>
    <ProjectCard title="Facebook Watch" link="https://techcrunch.com/2017/08/09/facebook-watch/" bg="linear-gradient(to right, #1978F2 0%, #1978F2 50%, #3B5988 100%)" mdxType="ProjectCard">
  Next gen video player enabling immersive and shared video consumption
  experiences
    </ProjectCard>
    <ProjectCard title="Focus Camera Mode" link="https://about.instagram.com/blog/announcements/introducing-focus-camera-mode" bg="linear-gradient(to right, #4C5FD7 0%, #7232Bd 25%, #C32AA3 50%, #F46F30 75%, #FFDC7D 100%)" mdxType="ProjectCard">
  A new camera mode for Instagram that democratizes access to professional
  camera aesthetics
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      